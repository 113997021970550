<!-- @format -->

<template>
  <div v-if="!pageLoading">
    <Slider
      v-if="slides && slides.length > 0"
      :slides="slides"
      auto-play
      lazy-load
      add-high-priority-on-first-slide
      :promotion-data="{
        name: 'slider_homepage',
        slot: '1',
        version: slides[0].creativeVersion,
      }"
    />
    <clientOnly>
      <RecentlyView
        v-if="
          recentlyViewProductGE2 &&
          $localStorage &&
          $store.state.ui.deviceType !== 'pc'
        "
        mobile-style
      />
    </clientOnly>
    <HighlightHome
      v-if="highlightHomeTop && highlightHomeTop.length > 0"
      :items="highlightHomeTop"
      :promotion-data="{
        name: 'homepage_mise_en_avant_top',
        slot: '2',
        version: highlightHomeTop[0].creativeVersion,
      }"
    />
    <clientOnly>
      <RecentlyView
        v-if="
          recentlyViewProductGE2 &&
          $localStorage &&
          $store.state.ui.deviceType === 'pc'
        "
      />
    </clientOnly>
    <HighlightProducts
      v-if="highlightedProducts && highlightedProducts.length > 0"
      :products="highlightedProducts"
      :promotion-data="{
        name: 'top_categorie_homepage',
        slot: '3',
        version: highlightedProducts[0].creativeVersion,
      }"
    />
    <HighlightHome
      v-if="highlightHomeBottom && highlightHomeBottom.length > 0"
      :items="highlightHomeBottom"
      :promotion-data="{
        name: 'homepage_mise_en_avant_bottom',
        slot: '4',
        version: highlightHomeBottom[0].creativeVersion,
      }"
      bottom
    />
    <ProductListNosto id="frontpage-nosto-1" />
    <TopSales
      v-if="topSales && topSales.length > 0"
      :products="topSales"
      listing-type="LISTING_TOP_SALES_HOMEPAGE"
    />
    <HighlightStyles
      v-if="highlightedStyles && highlightedStyles.length > 0"
      :styles="highlightedStyles"
      :promotion-data="{
        name: 'style_du_moment_homepage',
        slot: '5',
        version: highlightedStyles[0].creativeVersion,
      }"
    />
    <ProductListNosto
      id="frontpage-nosto-2"
      @hook:mounted="productListNostoMounted()"
    />
    <BestOffers
      v-if="bestOffers && bestOffers.length > 0"
      :products="bestOffers"
      listing-type="LISTING_BEST_OFFERS_HOMEPAGE"
    />
    <FollowUs
      v-if="followUs && followUs.length > 0"
      :items="followUs"
      :promotion-data="{
        name: 'wall_instagram',
        slot: '6',
        version: followUs[0].creativeVersion,
      }"
    />
    <ConnectModal v-if="showModalConnect" type="HOME" />
  </div>
  <Skeleton v-else />
</template>

<script>
// Config/Store
import { mapActions, mapGetters, mapState } from 'vuex'
import { URL_PROTOCOL } from '@/config'
import { TYPE } from '@/config/algolia/config'
import { INTER_DOMAIN } from '@/config/international/config'

// Misc
import { generateItemObjectV2 } from '@/helpers/gtmHelper'
import { sendSentryMessage } from '@/helpers/errorHelper'

// Mixins
import GtmMixin from '@/mixins/gtm'
import PageMixin from '@/mixins/page'
import skeletonMixin from '@/mixins/skeletonMixin'

// Components
import BestOffers from '@/components/organisms/home/best-offers/BestOffers'
import FollowUs from '@/components/organisms/home/follow-us/FollowUs'
import HighlightHome from '@/components/organisms/home/highlight-home/HighlightHome'
import HighlightProducts from '@/components/organisms/home/highlight-products/HighlightProducts'
import HighlightStyles from '@/components/organisms/home/highlight-styles/HighlightStyles'
import Slider from '@/components/molecules/global/slider/Slider'
import Skeleton from '@/components/organisms/skeleton/Skeleton.vue'
import TopSales from '@/components/organisms/home/top-sales/TopSales'
import ProductListNosto from '@/components/molecules/listing/product-list-nosto/ProductListNosto'

export default {
  name: 'Index',
  components: {
    BestOffers,
    ConnectModal: () =>
      import(
        '@/components/molecules/connection/connect-popin/ConnectModal.vue'
      ),
    FollowUs,
    HighlightHome,
    HighlightProducts,
    HighlightStyles,
    RecentlyView: () =>
      import('@/components/organisms/home/recently-view/RecentlyView.vue'),
    Skeleton,
    Slider,
    TopSales,
    ProductListNosto,
  },
  mixins: [skeletonMixin, PageMixin, GtmMixin],
  layout: 'home',
  auth: false,
  async asyncData(context) {
    const { store, query, error } = context
    const currentInterStore = store.state.international.currentInterStore

    store.commit('ui/UPDATE_ASYNC_DATA_LOADING', true)

    if (!currentInterStore) {
      sendSentryMessage.bind(context, 'Store is undefined or null', 'fatal', {
        action: 'AsyncData index (home)',
        currentInterStore,
      })()

      return error({ statusCode: 500 })
    }

    const algoliaIndex = currentInterStore.algoliaProductIndex

    // Display the outlet popin if outlet is in query params
    if (query.outlet) {
      store.dispatch('togglePopinOutlet', true)
    }
    // Fetch the homepage data
    let slides,
      highlightedProducts,
      highlightedStyles,
      followUs,
      highlightHomeTop,
      highlightHomeBottom

    const templateItemKeys = [
      [
        'slider_homepage',
        'wall_instagram',
        'top_categorie_homepage',
        'style_du_moment_homepage',
        'homepage_mise_en_avant_top',
        'homepage_mise_en_avant_bottom',
      ],
    ]

    // Pour bénéficier du cache redis côté client
    let templateItems
    let bestOffers1
    let topSales

    try {
      const homeData = await context.$axios.$post('/views/home', {
        algoliaIndex,
        langCode: currentInterStore.langCode,
        storeId: currentInterStore.id,
        templateItemKeys,
      })

      templateItems = homeData.templateItems
      bestOffers1 = homeData.bestOffers1
      topSales = homeData.topSales
    } catch (err) {
      sendSentryMessage.bind(
        context,
        'Unable to fetch templates on Homepage from internal api',
        'error',
        {
          call: 'getTemplatesItems',
          exception: err,
        },
      )()
    }

    if (!Array.isArray(templateItems) || !templateItems.length) {
      sendSentryMessage.bind(
        context,
        'No template items found on homepage',
        'error',
        {
          call: 'getTemplatesItems',
          action: 'set template items data',
          currentInterStore,
          templateItems,
        },
      )()

      return error({ statusCode: 500 })
    }

    // Why object freeze : https://vuedose.tips/improve-performance-on-large-lists-in-vue-js/
    const slidesTemplate = templateItems.find(
      (item) => item.slug === 'slider_homepage',
    )
    if (slidesTemplate) {
      slides = Object.freeze(slidesTemplate.templateItems)
    }

    const topCategoriesTemplate = templateItems.find(
      (item) => item.slug === 'top_categorie_homepage',
    )
    if (topCategoriesTemplate) {
      highlightedProducts = Object.freeze(topCategoriesTemplate.templateItems)
    }

    const highlightedStylesTemplate = templateItems.find(
      (item) => item.slug === 'style_du_moment_homepage',
    )
    if (highlightedStylesTemplate) {
      highlightedStyles = Object.freeze(highlightedStylesTemplate.templateItems)
    }

    const followUsTemplate = templateItems.find(
      (item) => item.slug === 'wall_instagram',
    )
    if (followUsTemplate) {
      followUs = Object.freeze(followUsTemplate.templateItems)
    }

    const highlightHomeTopTemplate = templateItems.find(
      (item) => item.slug === 'homepage_mise_en_avant_top',
    )
    if (highlightHomeTopTemplate) {
      highlightHomeTop = Object.freeze(highlightHomeTopTemplate.templateItems)
    }

    const highlightHomeBottomTemplate = templateItems.find(
      (item) => item.slug === 'homepage_mise_en_avant_bottom',
    )
    if (highlightHomeBottomTemplate) {
      highlightHomeBottom = Object.freeze(
        highlightHomeBottomTemplate.templateItems,
      )
    }

    // best offers : more info on notion : https://www.notion.so/laboutiqueofficielle/Nuxt-Front-LOT-2-8f6833847e324dab8e4fa0eaaf3dcd0f#dc839960a6aa48a7a7a15d5d30a30850
    // First fetch
    let bestOffers = bestOffers1?.hits
    // If first fetch doesn't send 12 product, complete with other product
    if (bestOffers.length < 12) {
      try {
        const { bestOffers2 } = await context.$axios.$post(
          '/views/home/best-offers-2',
          {
            algoliaIndex,
            langCode: currentInterStore.langCode,
            storeId: currentInterStore.id,
          },
        )

        // Remove product already fecth in first fecth
        const filtreredBestOffers2Hits = bestOffers2?.hits
          .filter(
            (i) => !bestOffers.map((j) => j.productID).includes(i.productID),
          )
          .slice(0, 12 - bestOffers.length)
        bestOffers = [...bestOffers, ...filtreredBestOffers2Hits]
      } catch (err) {
        sendSentryMessage.bind(
          context,
          'Unable to get Algolia Homepage best offers (2) from internal api',
          'error',
          {
            index: algoliaIndex,
            store: currentInterStore,
            type: TYPE.BESTOFFERS2,
            exception: err,
          },
        )()
      }
    }
    const pageUrl = `${URL_PROTOCOL}://${INTER_DOMAIN[store.state.international.currentInterStore.id]}`

    store.commit('ui/UPDATE_ASYNC_DATA_LOADING', false)

    return {
      slides,
      highlightedProducts,
      highlightedStyles,
      topSales: topSales?.hits,
      bestOffers,
      followUs,
      highlightHomeTop,
      highlightHomeBottom,
      pageUrl,
      recentlyViewProduct: null,
    }
  },
  head() {
    const head = this.headData
    head.link.push({ rel: 'canonical', href: `${this.pageUrl}` })
    return head
  },
  computed: {
    ...mapState({
      showModalConnect: (state) => state.showModalConnect,
      readyToTrack: (state) => state.gtm && state.gtm.readyToTrack,
    }),

    ...mapGetters({
      countryCode: 'international/getCountryCode',
    }),

    recentlyViewProductGE2() {
      return this.recentlyViewProduct && this.recentlyViewProduct.length >= 2
    },
  },
  watch: {
    readyToTrack() {
      this.trackingIndexView()
    },
  },
  mounted() {
    setTimeout(() => this.setHomeBanner(null), 3000)
    const storedRecentlyViews = this.$localStorage?.getItem('recentlyView')
    if (storedRecentlyViews) {
      this.recentlyViewProduct = JSON.parse(storedRecentlyViews)
    }
  },
  methods: {
    ...mapActions({
      setHomeBanner: 'setHomeBanner',
    }),
    /* FIXME: the following method (and the hook used) is a "hack" because of the skeleton feature
    / indeed, the skeleton feature cause components to be asynchronously loaded in the page
    / resulting in bad event chaining throughout the app (tracking view in this situation which need to access the component ProductListNosto DOM)
    / to remove when skeleton rework is live */
    productListNostoMounted() {
      this.trackingIndexView()
    },
    trackingIndexView() {
      if (this.readyToTrack) {
        const [topSalesTrackingArray, bestOffersTrackingArray] = [
          this.generateTrackingArrayFromAlgoliaData(this.topSales),
          this.generateTrackingArrayFromAlgoliaData(this.bestOffers),
        ]
        const mergedTrackingArrays = [
          ...topSalesTrackingArray,
          ...bestOffersTrackingArray,
        ]

        this.$gtm.trackEvent({
          event: 'lbo_index_view',
          item: mergedTrackingArrays,
        })
      }
    },
    generateTrackingArrayFromAlgoliaData(data) {
      const array = []

      if (!data || !Array.isArray(data)) {
        return array
      }

      data.forEach((item) => {
        array.push(
          generateItemObjectV2({
            product: item,
            isAlgoliaData: true,
            locale: this.$i18n.locale,
            countryCode: this.countryCode,
          }),
        )
      })

      return array
    },
  },
}
</script>
