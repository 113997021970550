<template>
  <div v-if="items" class="c-insta">
    <MainHeading :subtitle="$t('components.organisms.home.followUs.subtitle')" :title="$t('components.organisms.home.followUs.title')">
      {{ $t('components.organisms.home.followUs.text') }}
    </MainHeading>
    <ul class="c-insta__list">
      <li v-for="(item, key) in items" :key="item.id" class="c-insta__item">
        <button
          @click.prevent="trackingEndRedirect(item.link)"
        >
          <div class="c-insta__overlay">
            <p class="c-insta__btn">
              {{ item.title }}
            </p>
          </div>
          <picture>
            <source
              media="(min-width:63.99em)"
              loading="lazy"
              :data="item.image"
              :alt="item.altText"
            >
            <img
              loading="lazy"
              :src="item.imageMobile"
              :alt="item.altText"
              :width="generateImageSize(key+1)"
              :height="generateImageSize(key+1)"
            >
          </picture>
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { createTrackPromotionEvent } from '@/helpers/gtmHelper'
import MainHeading from '@/components/molecules/global/main-heading/MainHeading'
export default {
  components: { MainHeading },
  props: {
    items: {
      type: Array,
      default: null
    },
    promotionData: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      readyToTrack: state => state.gtm && state.gtm.readyToTrack
    })
  },
  watch: {
    readyToTrack () {
      this.startTrackingViewPromotion()
    }
  },
  mounted () {
    this.startTrackingViewPromotion()
  },
  methods: {
    startTrackingViewPromotion () {
      // if (this.readyToTrack && this.promotionData?.length) {
      //   this.$gtm.trackEvent(
      //     createTrackPromotionEvent('view_promotion', this.promotionData)
      //   )
      // }
    },
    generateImageSize (pos) {
      return pos === 1 || pos === 2 ? 404 : 202
    },
    trackingEndRedirect (link) {
      if (this.promotionData) {
        this.$gtm.trackEvent(
          createTrackPromotionEvent('select_promotion', this.promotionData)
        )
      }
      this.$router.push({ path: this.localePath(link) })
    }
  }
}
</script>
<style lang="scss" scoped>
.c-insta {
  $c: &;

  width: 100%;
  max-width: 110rem;
  padding: 0 4.5rem;
  margin: 0 auto;
  margin-bottom: 12rem;

  // Surcharge components main-heading
  .c-main-heading {
    margin-bottom: 4.1rem;

    @include mq($until: tablet) {
      margin-bottom: 2.3rem;
    }
  }

  @include mq($until: tablet) {
    max-width: 100%;
    padding: 0 2.3rem;
    margin-bottom: 5.8rem;
  }

  &__list {
    display: grid;
    padding-left: 0;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "pos1 pos1 pos7 pos8 pos9"
      "pos1 pos1 pos6 pos2 pos2"
      "pos3 pos4 pos5 pos2 pos2";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // stylelint-disable-next-line declaration-block-no-redundant-longhand-properties
    grid-template-rows: 1fr 1fr 1fr;
    list-style-type: none;

    @include mq($until: tablet) {
      -ms-grid-columns: 1fr 1fr 1fr;
      -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "pos1 pos1 pos7"
        "pos1 pos1 pos8"
        "pos9 pos6 pos3"
        "pos4 pos2 pos2"
        "pos5 pos2 pos2";
      grid-template-columns: 1fr 1fr 1fr;
      // stylelint-disable-next-line declaration-block-no-redundant-longhand-properties
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  &__item {
    background-color: $grey-1300;

    &:nth-child(1) {
      grid-area: pos1;
    }

    &:nth-child(2) {
      grid-area: pos2;
    }

    &:nth-child(3) {
      grid-area: pos3;
    }

    &:nth-child(4) {
      grid-area: pos4;
    }

    &:nth-child(5) {
      grid-area: pos5;
    }

    &:nth-child(6) {
      grid-area: pos6;
    }

    &:nth-child(7) {
      grid-area: pos7;
    }

    &:nth-child(8) {
      grid-area: pos8;
    }

    &:nth-child(9) {
      grid-area: pos9;
    }

    button {
      position: relative;
      display: block;
      margin-bottom: 0;

      &:hover,
      &:focus {
        #{$c}__overlay {
          opacity: 1;
        }
      }

      &:focus-visible {
        outline: none;
      }

      picture {
        display: block;

        img {
          position: relative;
          z-index: 1;
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba($primary-light, 0.9);
    opacity: 0;
    transition: $default-transition;

    @include mq($until: tablet) {
      display: none;
    }
  }

  &__btn {
    padding: 2.2rem;
    border: 0.2rem solid $white;
    color: $white;
    cursor: pointer;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
  }
}
</style>
