<template>
  <div v-if="styles" class="c-highlight-styles">
    <MainHeading
      :subtitle="$t('components.organisms.home.highlightStyles.subtitle')"
      :title="$t('components.organisms.home.highlightStyles.title')"
    />
    <div class="c-highlight-styles__main">
      <ul class="c-highlight-styles__list">
        <li
          v-if="styles && styles[0]"
          class="c-highlight-styles__item u-1/2 u-1/1@tablet"
        >
          <button
            @click.prevent="trackingClickEndRedirect(styles[0].link)"
          >
            <picture class="c-highlight-styles__media">
              <source
                media="(min-width:47.99em)"
                loading="lazy"
                :srcset="styles[0].image"
              >
              <img
                loading="lazy"
                :src="styles[0].imageMobile"
                :alt="styles[0].altText"
                width="538"
                height="719"
              >
            </picture>
          </button>

          <ul class="c-highlight-styles__sub-list">
            <li
              v-if="styles && styles[2]"
              class="c-highlight-styles__sub-list-item u-1/2"
            >
              <button
                @click.prevent="trackingClickEndRedirect(styles[2].link)"
              >
                <LazyImage
                  :image="styles[2].image"
                  :alt="styles[2].altText"
                  class="c-highlight-styles__sub-list-media"
                  :width="345"
                  :height="345"
                />
                <div class="c-highlight-styles__sub-list-title">
                  {{ styles[2].title }}
                </div>
              </button>
            </li>
            <li
              v-if="styles && styles[3]"
              class="c-highlight-styles__sub-list-item u-1/2"
            >
              <button
                @click.prevent="trackingClickEndRedirect(styles[3].link)"
              >
                <LazyImage
                  :image="styles[3].image"
                  :alt="styles[3].altText"
                  class="c-highlight-styles__sub-list-media"
                  :width="345"
                  :height="345"
                />
                <div class="c-highlight-styles__sub-list-title">
                  {{ styles[3].title }}
                </div>
              </button>
            </li>
          </ul>
        </li>
        <li
          v-if="styles && styles[1]"
          class="c-highlight-styles__item u-1/2 u-1/1@tablet"
        >
          <button
            @click.prevent="trackingClickEndRedirect(styles[1].link)"
          >
            <picture class="c-highlight-styles__media">
              <source
                media="(min-width:47.99em)"
                :srcset="styles[1].image"
                loading="lazy"
              >
              <img
                :src="styles[1].imageMobile"
                :alt="styles[1].altText"
                loading="lazy"
                width="538"
                height="719"
              >
            </picture>
          </button>
          <ul class="c-highlight-styles__sub-list">
            <li
              v-if="styles && styles[4]"
              class="c-highlight-styles__sub-list-item u-1/2"
            >
              <button
                @click.prevent="trackingClickEndRedirect(styles[4].link)"
              >
                <LazyImage
                  :image="styles[4].image"
                  :alt="styles[4].altText"
                  class="c-highlight-styles__sub-list-media"
                  :width="345"
                  :height="345"
                />
                <div class="c-highlight-styles__sub-list-title">
                  {{ styles[4].title }}
                </div>
              </button>
            </li>
            <li
              v-if="styles && styles[5]"
              class="c-highlight-styles__sub-list-item u-1/2"
            >
              <button
                @click.prevent="trackingClickEndRedirect(styles[5].link)"
              >
                <LazyImage
                  :image="styles[5].image"
                  :alt="styles[5].altText"
                  class="c-highlight-styles__sub-list-media"
                  :width="345"
                  :height="345"
                />
                <div class="c-highlight-styles__sub-list-title">
                  {{ styles[5].title }}
                </div>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { createTrackPromotionEvent } from '@/helpers/gtmHelper'

import LazyImage from '@/components/atoms/global/lazy-image/LazyImage'
import MainHeading from '@/components/molecules/global/main-heading/MainHeading'

export default {
  components: { LazyImage, MainHeading },
  props: {
    styles: {
      type: Array,
      default: null
    },
    promotionData: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      readyToTrack: state => state.gtm && state.gtm.readyToTrack
    })
  },
  watch: {
    readyToTrack () {
      this.startTrackingViewPromotion()
    }
  },
  mounted () {
    this.startTrackingViewPromotion()
  },
  methods: {
    startTrackingViewPromotion () {
      // if (this.readyToTrack && this.promotionData?.length) {
      //   this.$gtm.trackEvent(
      //     createTrackPromotionEvent('view_promotion', this.promotionData)
      //   )
      // }
    },
    trackingClickEndRedirect (link) {
      if (this.promotionData?.length) {
        this.$gtm.trackEvent(
          createTrackPromotionEvent('select_promotion', this.promotionData)
        )
      }
      this.$router.push({ path: this.localePath(link) })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-highlight-styles {
  $c: &;

  padding: 0 8.1rem;
  margin-bottom: 9.2rem;

  &__main {
    position: relative;
    padding: 0 5.3rem;

    &::before {
      position: absolute;
      z-index: 0;
      top: 6.4rem;
      right: 0;
      bottom: 21.5%;
      left: 0;
      border: 0.4rem solid $primary;
      content: "";

      @include mq($until: tablet) {
        display: none;
      }
    }

    @include mq($until: tablet) {
      padding: 0;
    }
  }

  &__list {
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -1.1rem;
    list-style-type: none;

    @include mq($until: tablet) {
      margin: 0;
    }
  }

  &__item {
    padding: 0 1.1rem;
    margin-bottom: 0;

    > button,
    > a {
      position: relative;
      display: block;
      width: 100%;
      padding-bottom: 134%;
      margin-bottom: 5rem;
      background: $grey-1300;
      color: $grey-300;
      text-decoration: none;

      @include mq($until: tablet) {
        margin-bottom: 0.5rem;
      }

      picture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    .c-highlight-styles__sub-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0 -1.1rem;
      list-style-type: none;

      &__title {
        font-weight: 700;

        @include mq($until: tablet) {
          font-weight: 900;
        }
      }

      &__item {
        margin-bottom: 0;
      }

      @include mq($until: tablet) {
        padding: 0 !important;
        margin: 0 -0.25rem;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    .c-highlight-styles__sub-list-item {
      padding: 0 1.1rem;

      @include mq($until: tablet) {
        padding: 0 0.25rem;
      }

      > a,
      > button {
        display: block;
        width: 100%;
        color: $grey-300;
        text-decoration: none;

        &:hover,
        &:focus {
          color: $primary;
        }
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    .c-highlight-styles__sub-list-media {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 2rem;
      background-color: $grey-1300;

      @include mq($until: tablet) {
        margin-bottom: 1rem;
      }
    }

    // stylelint-disable-next-line selector-class-pattern
    .c-highlight-styles__sub-list-title {
      padding: 0 0.5rem;
      margin: 0;
      font-size: $font-size-xxl;
      font-weight: 700;
      letter-spacing: 0.3px;
      line-height: 1;
      text-align: center;
      text-transform: none;

      @include mq($until: tablet) {
        font-size: 1.5rem;
        font-weight: 900;
        letter-spacing: 0.074rem;
      }
    }

    &:first-child {
      // stylelint-disable-next-line selector-class-pattern
      .c-highlight-styles__sub-list {
        padding-left: 2.2rem;
      }
    }

    &:last-child {
      // stylelint-disable-next-line selector-class-pattern
      .c-highlight-styles__sub-list {
        padding-right: 2.2rem;
      }
    }

    @include mq($until: tablet) {
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 5.3rem;
      }
    }
  }

  &__media {
    > img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  // Surcharge c-main-heading
  .c-main-heading {
    margin-bottom: 3.2rem;
  }

  @include mq($until: tablet) {
    padding: 0 0.5rem;
    margin-bottom: 6.5rem;
  }
}
</style>
