<template>
  <div class="c-highlight-home" :class="{'c-highlight-home--bottom': bottom}">
    <div class="o-grid">
      <div v-for="(item, index) in items" :key="'highlight-'+ index" class="c-highlight-home__item" :class="size">
        <button
          v-if="item.image"
          @click.prevent="trackingClickEndRedirect(item)"
        >
          <LazyImage
            :image="generateImageLink(item.image)"
            :image-mobile="item.imageMobile"
            class="u-img--fluid"
            :alt="item.altText"
            :width="651"
            :height="292"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { createTrackPromotionEvent } from '@/helpers/gtmHelper'
import LazyImage from '@/components/atoms/global/lazy-image/LazyImage'
/**
 * the `highlightHome` component : Display Images + link on home page. Designed for 2 images, but the design is responsive add adative if more items are passed as props.
 */
export default {
  components: { LazyImage },
  props: {
    /**
     * Array of items. Each item should contains the following props :
     *  position,
     *  title,
     *  image,
     *  altText,
     *  imageMobile,
     *  altTextMobile,
     *  link,
     *  linkMobile
     */
    items: {
      type: Array,
      required: true
    },
    bottom: {
      type: Boolean,
      default: false
    },
    promotionData: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      readyToTrack: state => state.gtm && state.gtm.readyToTrack
    }),
    size () {
      return `u-1/${this.items.length} u-1/1@tablet`
    }
  },
  watch: {
    readyToTrack () {
      this.startTrackingViewPromotion()
    }
  },
  mounted () {
    this.startTrackingViewPromotion()
  },
  methods: {
    startTrackingViewPromotion () {
      // if (this.readyToTrack && this.promotionData?.length) {
      //   this.$gtm.trackEvent(
      //     createTrackPromotionEvent('view_promotion', this.promotionData)
      //   )
      // }
    },
    generateImageLink (imgLink) {
      const linkSplit = imgLink.split('f_auto,')
      return `${linkSplit[0]}w_651,f_auto,${linkSplit[1]}`
    },
    trackingClickEndRedirect (item) {
      if (this.promotionData) {
        this.$gtm.trackEvent(
          createTrackPromotionEvent('select_promotion', this.promotionData)
        )
      }
      this.$router.push({ path: this.localePath(item.link) })
    }
  }
}

</script>

<style lang="scss" scoped>
.c-highlight-home {
  $c: &;

  overflow: hidden;
  padding: 0 2.2rem;
  margin: -1rem 0 6rem;

  a,
  button {
    all: unset;
    display: block;
    width: 100%;
    aspect-ratio: 651 / 292;
    background-color: $grey-1300;

    &:hover {
      cursor: pointer;
    }
  }

  .o-grid {
    margin: 0 -1rem;
  }

  &--bottom {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  &::v-deep img {
    width: 100%;
    height: auto;
    margin: auto;
  }

  @include mq($until: tablet) {
    padding: 0 0.5rem;
  }

  &__item + &__item {
    @include mq($until: tablet) {
      margin-top: 2rem;
    }
  }
}
</style>
