<template>
  <div :id="idNostoElement" class="c-product-list nosto_element" />
</template>

<script>
export default {
  props: {
    idNostoElement: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.c-product-list {
  $c: &;

  padding: 2.2rem;
  margin-bottom: 2rem;
  text-align: center;

  ::v-deep .c-main-heading__title {
    margin-top: 1rem;
  }

  &:empty {
    display: none;
  }

  // stylelint-disable-next-line selector-class-pattern
  ::v-deep .c-product-list__list {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem -1.1rem 1rem;
    text-align: left;

    > * {
      display: flex;
      padding-right: 1.1rem;
      padding-left: 1.1rem;
    }

    @include mq($until: tablet) {
      margin: 2.6rem -0.25rem 1rem !important;

      > * {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
      }
    }
  }

  @include mq($until: tablet) {
    padding: 0 0.5rem !important;
  }

  ::v-deep .c-product-thumbnail {
    $c: &;

    position: relative;
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    padding-bottom: 8rem;
    margin-bottom: 2.2rem;
    color: inherit;
    font-size: $font-size-sm;
    text-decoration: none;

    &__img-wrapper {
      position: relative;
      padding-bottom: 100%;
      background: $grey-1300;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }

    &__tag {
      position: absolute;
      z-index: 1;
      top: 1.4rem;
      left: 0;
      padding: 0.1rem 0.6rem;
      background: $primary;
      color: $white;
      font-weight: 700;
      letter-spacing: 0.13rem;
      text-transform: uppercase;
      &#{$c}__tag--grey {
        background-color: $grey-1100;
        color: $grey-300;
      }
    }

    &__info-wrapper {
      position: absolute;
      z-index: 1;
      top: 100%;
      width: 100%;
      background: $white;
      transform: translateY(-8rem);
      transition: transform 0.2s;
    }

    &__tag-wrapper {
      position: absolute;
      z-index: 1;
      bottom: calc(100% + 1.7rem);
      left: 0;

      @include mq($until: tablet) {
        bottom: calc(100% + 1.3rem);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &:empty {
        display: none;
      }

      .c-sticker {
        padding: 0.28rem 0.49rem;
        font-size: 1.1rem;
        line-height: normal;

        &:not(:last-child) {
          margin-right: 0.4rem;

          @include mq($until: tablet) {
            margin-right: 0;
            margin-bottom: 0.6rem;
          }
        }
      }
    }

    &__info {
      top: 0;
      display: flex;
      width: 100%;
      min-height: 8rem;
      flex-direction: column;
      flex-grow: 1;
      background: $white;
    }

    &__titleWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0.8rem 0 0.3rem;

      .c-sticker {
        padding: 0.4rem;
        margin-right: 1rem;
      }
    }

    &__title {
      overflow: hidden;
      padding: 0;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include mq($until: tablet) {
        letter-spacing: 0.1px;
      }
    }

    &__desc {
      /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
      flex-grow: 1;

      p {
        display: -webkit-box;
        overflow: hidden;
        margin: 0;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 1.23;
      }

      &--with-free-shipping {
        p {
          -webkit-line-clamp: 1;
        }
      }
    }

    &__free-shipping {
      color: $accent-light;
      font-weight: 500;
      letter-spacing: 0.1rem;
      text-transform: uppercase;

      @include mq($until: tablet) {
        font-size: $font-size-sm-mobile;
        letter-spacing: 0;
      }
    }

    &__price {
      display: flex;
      align-items: flex-end;
      margin-top: 0.3rem;

      .c-price + .c-price {
        margin-left: 2rem;
      }
    }

    &__size {
      display: flex;
      min-height: 3rem;
      flex-wrap: wrap;
      padding-top: 1.2rem;
      color: $grey-600;

      button {
        margin-right: 0.5rem;
        color: inherit;
        transition: color 0.2s ease;

        &:hover,
        &:focus {
          color: $grey-300;
        }
      }
    }
  }

  ::v-deep .c-price {
    $c: &;

    font-size: $font-size-md;
    font-weight: 700;
    line-height: 1;

    &--discount {
      color: $primary;
      font-size: $font-size-lg;
    }

    &--small {
      font-size: $font-size-sm;
    }

    &--info {
      font-size: 1.8rem;
      font-weight: 500;

      @include mq($until: tablet) {
        font-size: 1.5rem;
      }
    }

    &--old {
      margin-left: 2.7rem;
      font-weight: 400;
      text-decoration: line-through;

      @include mq($until: tablet) {
        margin-left: 1.5rem;
      }
    }

    &--large {
      font-size: $font-size-xl;

      @include mq($until: tablet) {
        font-size: $font-size-xl-mobile;
      }
    }

    &--small-on-mobile {
      @include mq($until: tablet) {
        font-size: $font-size-sm-mobile;
      }
    }

    &__with-discount {
      @include mq($until: tablet) {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }

    &__wrapper-discount {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &__promo {
      display: inline-block;
      padding: 0.2rem 1.1rem;
      margin: 0 0 1.3rem;
      background: $primary;
      color: $white;

      @include mq($until: tablet) {
        margin: 0.6rem 0 0;
      }
    }

    &__discount {
      margin: 0 0 1.3rem;
      color: $grey-600;
      font-size: $font-size-sm;
    }

    &__wrapper {
      @include mq($from: tablet) {
        display: flex;
      }

      &--basket {
        #{$c}__discount {
          span {
            color: $primary;
            font-weight: 700;
          }

          @include mq($until: tablet) {
            color: #8f8f8f;
            font-size: 1.1rem;
            font-weight: 400 !important;
          }
        }

        #{$c}__with-discount {
          display: flex;
          flex-direction: column;

          #{$c}__wrapper-discount {
            display: flex;
            flex-direction: column;

            // stylelint-disable-next-line selector-class-pattern
            .c-price--discount.c-price--large {
              font-size: 1.5rem;
            }

            @include mq($until: tablet) {
              align-items: flex-end;
            }
          }
          #{$c} {
            margin-left: 0;
            font-size: 1.3rem;
            line-height: inherit;
          }

          #{$c}__discount {
            order: 2;
            margin-bottom: 0;
            font-weight: 700;

            @include mq($until: tablet) {
              order: 0;
            }
          }

          #{$c}--old {
            order: 0;

            @include mq($until: tablet) {
              font-size: $font-size-sm-mobile;
            }
          }

          #{$c}--discount {
            order: 1;
            font-size: 1.8rem;
          }
        }
      }

      &--inverted {
        #{$c}__with-discount {
          #{$c}__wrapper-discount {
            display: flex;
          }
          #{$c}--old {
            order: 0;
            margin-left: 0;
          }

          #{$c}--discount {
            order: 1;
            margin-left: 1.5rem;
            color: inherit;
          }
        }
      }
    }

    &--summary {
      #{$c}__with-discount {
        #{$c}__wrapper-discount {
          display: block;
          min-width: 55px;
          line-height: 0.9;
          text-align: right;
        }
      }

      #{$c}__discount {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: $font-size-xs-mobile;

        span {
          color: $primary;
        }
      }

      #{$c}--old {
        margin-top: 0.5rem;
        margin-bottom: 0.2rem;
        margin-left: 0;
        font-size: $font-size-xs-mobile;
      }
    }
  }

  ::v-deep .c-sticker {
    display: inline-block;
    padding: 0.5rem;
    background-color: $primary-dark;
    color: $white;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 0.8rem;
    text-align: center;
    text-transform: uppercase;

    &--promo {
      font-weight: 700;
      letter-spacing: 0.13rem;
      text-transform: uppercase;
    }
  }
}
</style>
