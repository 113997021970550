<template>
  <ProductList
    :title="$t('components.organisms.home.bestOffers.title')"
    :products="products"
    :listing-type="listingType"
    no-market-label
  />
</template>

<script>
import ProductList from '@/components/molecules/listing/product-list/ProductList'

export default {
  components: { ProductList },
  props: {
    listingType: {
      type: String,
      default: ''
    },
    products: {
      type: Array,
      default: null
    }
  }
}
</script>
